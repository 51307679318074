import _sortBy from "lodash/sortBy";
import _uniqBy from "lodash/uniqBy";
import AnalyticsQueryBuilder from "@/utils/AnalyticsQueryBuilder";

export default class AnalyticsService {
  constructor($optix) {
    this.graphQLService = $optix.ws;
    this.organizationId = parseInt($optix.env.get.organization_id, 10);
  }

  query(payload) {
    return this.graphQLService.graphQL(
      `query analytics(
        $organization_id: ID!
        $entities: [AnalyticsQueryEntityInput]
        $fields: [AnalyticsQueryFieldInput]
        $conditions: [AnalyticsQueryConditionInput]
        $order_by: [AnalyticsQueryOrder]
        $page: Int
        $per_page: Int
      ) {
        analyticsQuery(
          organization_id: $organization_id
          query: {
            entities: $entities
            fields: $fields
            conditions: $conditions
            order_by: $order_by
            page: $page
            per_page: $per_page
          }
        ) {
          currency
          currency_symbol
          timezone
          locale
          last_update_timestamp
          total
          per_page
          page
          columns {
            name
            title
            type
            description
          }
          data
        }
      }
      `,
      {
        organization_id: this.organizationId,
        entities: payload.entities,
        fields: payload.fields,
        conditions: payload.conditions,
        order_by: payload.orderBy,
        page: payload.page,
        per_page: payload.limit,
      }
    );
  }

  async getTypesOfRevenue() {
    const response = await this.query(
      new AnalyticsQueryBuilder()
        .from("REVENUE")
        .groupBy("REVENUE.type")
        .toObject()
    );

    if (response.data.errors) {
      return [];
    }

    return response.data.data.analyticsQuery.data.map((value) => ({
      text: value[0],
      value: value[0],
    }));
  }

  /**
   * @deprecated
   */
  async getSubscribedPlans() {
    const response = await this.query(
      new AnalyticsQueryBuilder()
        .from("SUBSCRIPTION")
        .with("PLAN", { name: "asSubscriptionPlan" })
        .groupBy("PLAN.name")
        .aggregate("SUBSCRIPTION.plan_id", "ANY_VALUE")
        .orderBy("PLAN.name")
        .toObject()
    );

    if (response.data.errors) {
      return [];
    }

    return response.data.data.analyticsQuery.data.map((value) => ({
      text: value[0],
      value: value[1],
    }));
  }

  async getSubscribedPlanTemplates() {
    const response = await this.query(
      new AnalyticsQueryBuilder()
        .from("ACCOUNT_PLAN")
        .with("PLAN_TEMPLATE", { name: "asAccountPlanTemplate" })
        .groupBy("PLAN_TEMPLATE.name")
        .aggregate("ACCOUNT_PLAN.plan_template_id", "ANY_VALUE")
        .orderBy("PLAN_TEMPLATE.name")
        .toObject()
    );

    if (response.data.errors) {
      return [];
    }

    return response.data.data.analyticsQuery.data.map((value) => ({
      text: value[0],
      value: value[1],
    }));
  }

  /**
   * @deprecated
   */
  mergePlanOptions(planOptions, planTemplateOptions) {
    const customPlan = {
      text: "Custom plan",
      value: "0",
    };
    const all = planTemplateOptions.concat(planOptions).concat([customPlan]);
    return _sortBy(_uniqBy(all, "value"), "text");
  }

  mergeFilterOptions(someOptions, otherOptions) {
    return _sortBy(_uniqBy(someOptions.concat(otherOptions), "value"), "text");
  }

  async getPlansUsedForCheckins() {
    const response = await this.query(
      new AnalyticsQueryBuilder()
        .from("CHECKIN")
        .groupBy("CHECKIN.plan_name")
        .orderBy("CHECKIN.plan_name")
        .where("CHECKIN.is_using_plan", 1)
        .toObject()
    );

    if (response.data.errors) {
      return [];
    }

    return response.data.data.analyticsQuery.data.map((value) => ({
      text: value[0],
      value: value[0],
    }));
  }

  async getBookingSources() {
    const response = await this.query(
      new AnalyticsQueryBuilder()
        .from("BOOKING")
        .groupBy("BOOKING.source")
        .toObject()
    );

    if (response.data.errors) {
      return [];
    }

    return response.data.data.analyticsQuery.data.map((value) => ({
      text: value[0],
      value: value[0],
    }));
  }

  async getResourceTypes() {
    const response = await this.query(
      new AnalyticsQueryBuilder()
        .from("RESOURCE")
        .groupBy("RESOURCE.type")
        .toObject()
    );

    if (response.data.errors) {
      return [];
    }

    return response.data.data.analyticsQuery.data.map((value) => ({
      text: value[0],
      value: value[0],
    }));
  }

  async getPlansUsedForBookings() {
    const response = await this.query(
      new AnalyticsQueryBuilder()
        .from("BOOKING")
        .groupBy("BOOKING.plan_name")
        .where("BOOKING.plan_name", "!=", "")
        .toObject()
    );

    if (response.data.errors) {
      return [];
    }

    return response.data.data.analyticsQuery.data.map((value) => ({
      text: value[0],
      value: value[0],
    }));
  }

  async getResourcesUsedForBookings() {
    const response = await this.query(
      new AnalyticsQueryBuilder()
        .from("BOOKING")
        .with("RESOURCE", { name: "asBookingResource" })
        .where("BOOKING.status", "!=", "Canceled")
        .aggregate("RESOURCE.name", "ANY_VALUE")
        .groupBy("RESOURCE.resource_id")
        .orderBy("RESOURCE.name")
        .toObject()
    );

    if (response.data.errors) {
      return [];
    }

    return response.data.data.analyticsQuery.data.map((value) => ({
      text: value[0],
      value: value[1],
    }));
  }

  async getResourcesUsedForAssignments() {
    const response = await this.query(
      new AnalyticsQueryBuilder()
        .from("ASSIGNMENT")
        .with("RESOURCE", { name: "asAssignmentResource" })
        .where("ASSIGNMENT.status", "!=", "Canceled")
        .aggregate("RESOURCE.name", "ANY_VALUE")
        .groupBy("RESOURCE.resource_id")
        .orderBy("RESOURCE.name")
        .toObject()
    );

    if (response.data.errors) {
      return [];
    }

    return response.data.data.analyticsQuery.data.map((value) => ({
      text: value[0],
      value: value[1],
    }));
  }

  async getResourceTypesUsedForBookings() {
    const response = await this.query(
      new AnalyticsQueryBuilder()
        .from("BOOKING")
        .with("RESOURCE", { name: "asBookingResource" })
        .where("BOOKING.status", "!=", "Canceled")
        .groupBy("RESOURCE.type")
        .orderBy("RESOURCE.type")
        .toObject()
    );

    if (response.data.errors) {
      return [];
    }

    return response.data.data.analyticsQuery.data.map((value) => ({
      text: value[0],
      value: value[0],
    }));
  }

  async getResourceTypesUsedForAssignments() {
    const response = await this.query(
      new AnalyticsQueryBuilder()
        .from("ASSIGNMENT")
        .with("RESOURCE", { name: "asAssignmentResource" })
        .where("ASSIGNMENT.status", "!=", "Canceled")
        .groupBy("RESOURCE.type")
        .orderBy("RESOURCE.type")
        .toObject()
    );

    if (response.data.errors) {
      return [];
    }

    return response.data.data.analyticsQuery.data.map((value) => ({
      text: value[0],
      value: value[0],
    }));
  }
}
